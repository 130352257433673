.dice-roller {
  padding: var(--spacing-lg) 0;
}

.dice-roller-card {
  border: none;
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  overflow: hidden;
  margin-bottom: var(--spacing-xl);
}

.dice-roller-card .card-header {
  background: linear-gradient(135deg, var(--color-primary), var(--color-secondary));
  border: none;
  padding: var(--spacing-md) var(--spacing-lg);
}

.dice-title {
  font-family: var(--heading-font-family);
  color: white;
  margin: 0;
  font-size: 1.8rem;
}

.form-container {
  margin: var(--spacing-md) 0;
}

.form-input {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  padding: 0.6rem 1rem;
  width: 100%;
  transition: all 0.3s ease;
}

.form-input:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 0.2rem rgba(94, 96, 206, 0.25);
}

.roll-button {
  background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: var(--border-radius-md);
  font-weight: 500;
  box-shadow: var(--shadow-sm);
  transition: all 0.3s ease;
}

.roll-button:hover:not(:disabled) {
  box-shadow: var(--shadow-md);
  transform: translateY(-2px);
}

.roll-button:disabled {
  background: linear-gradient(45deg, #a5a6e0, #a7e7e7);
  opacity: 0.7;
}

.results {
  padding: var(--spacing-md) 0;
}

.loading-spinner {
  padding: var(--spacing-lg) 0;
}

.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rolled-dice {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--spacing-sm);
  margin: var(--spacing-md) 0;
}

.die {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  position: relative;
  margin: var(--spacing-xs);
  animation: diceAppear 0.5s ease-out;
}

@keyframes diceAppear {
  from {
    opacity: 0;
    transform: scale(0.7) rotate(20deg);
  }
  to {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
}

.stats-container {
  width: 100%;
}

.stats-container h5 {
  font-family: var(--heading-font-family);
  color: var(--color-text-primary);
}

.timestamp-container {
  margin-top: var(--spacing-md);
  padding: var(--spacing-md);
  background-color: var(--color-light-gray);
  border-radius: var(--border-radius-md);
  width: 100%;
  max-width: 350px;
}

.time-elapsed {
  margin-top: var(--spacing-xs);
  color: var(--color-text-secondary);
  font-size: 0.9rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .die {
    width: 50px;
    height: 50px;
    font-size: 1.3rem;
  }
  
  .dice-title {
    font-size: 1.6rem;
  }
}

@media (max-width: 576px) {
  .die {
    width: 40px;
    height: 40px;
    font-size: 1.1rem;
  }
  
  .dice-title {
    font-size: 1.4rem;
  }
  
  .dice-roller {
    padding: var(--spacing-md) var(--spacing-sm);
  }
}