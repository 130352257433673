.coin-flipper {
  padding: var(--spacing-lg) 0;
}

.coin-card {
  border: none;
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  overflow: hidden;
  margin-bottom: var(--spacing-xl);
}

.coin-card .card-header {
  background: linear-gradient(135deg, var(--color-primary), var(--color-secondary));
  border: none;
  padding: var(--spacing-md) var(--spacing-lg);
}

.coin-title {
  font-family: var(--heading-font-family);
  color: white;
  margin: 0;
  font-size: 1.8rem;
}

.form-container {
  margin: var(--spacing-md) 0;
}

.form-input {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  padding: 0.6rem 1rem;
  width: 100%;
  transition: all 0.3s ease;
}

.form-input:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 0.2rem rgba(94, 96, 206, 0.25);
}

.flip-button {
  background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: var(--border-radius-md);
  font-weight: 500;
  box-shadow: var(--shadow-sm);
  transition: all 0.3s ease;
}

.flip-button:hover:not(:disabled) {
  box-shadow: var(--shadow-md);
  transform: translateY(-2px);
}

.flip-button:disabled {
  background: linear-gradient(45deg, #a5a6e0, #a7e7e7);
  opacity: 0.7;
}

.results {
  padding: var(--spacing-md) 0;
}

.loading-spinner {
  padding: var(--spacing-lg) 0;
}

.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flipped-coins {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--spacing-sm);
  margin: var(--spacing-md) 0;
}

.coin {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 50%;
  margin: var(--spacing-xs);
  box-shadow: var(--shadow-md);
  position: relative;
  animation: coinFlip 0.6s ease-out;
  transform-style: preserve-3d;
}

.coin.heads {
  background: linear-gradient(135deg, #FFC107, #FFD700);
  color: var(--color-text-primary);
}

.coin.tails {
  background: linear-gradient(135deg, #B8B9C6, #D3D3D3);
  color: var(--color-text-primary);
}

@keyframes coinFlip {
  0% {
    transform: rotateY(0) scale(0.5);
    opacity: 0;
  }
  50% {
    transform: rotateY(720deg) scale(0.8);
  }
  100% {
    transform: rotateY(1440deg) scale(1);
    opacity: 1;
  }
}

.stats-container {
  width: 100%;
}

.stats-container h5 {
  font-family: var(--heading-font-family);
  color: var(--color-text-primary);
}

.timestamp-container {
  margin-top: var(--spacing-md);
  padding: var(--spacing-md);
  background-color: var(--color-light-gray);
  border-radius: var(--border-radius-md);
  width: 100%;
  max-width: 350px;
}

.time-elapsed {
  margin-top: var(--spacing-xs);
  color: var(--color-text-secondary);
  font-size: 0.9rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .coin {
    width: 70px;
    height: 70px;
  }
  
  .coin-title {
    font-size: 1.6rem;
  }
}

@media (max-width: 576px) {
  .coin {
    width: 60px;
    height: 60px;
    font-size: 0.9rem;
  }
  
  .coin-title {
    font-size: 1.4rem;
  }
  
  .coin-flipper {
    padding: var(--spacing-md) var(--spacing-sm);
  }
}