/* Home component styles using our theme variables */
.home-container {
  padding: var(--spacing-lg) 0;
  max-width: 1200px;
  margin: 0 auto;
}

/* Hero section */
.hero-section {
  text-align: center;
  position: relative;
  padding: var(--spacing-xl) var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
  overflow: hidden;
}

.app-title {
  font-family: var(--heading-font-family);
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: var(--spacing-md);
  background: linear-gradient(135deg, var(--color-primary), var(--color-secondary));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeIn 1s ease-in-out;
}

.app-subtitle {
  font-size: 1.3rem;
  color: var(--color-text-secondary);
  margin-bottom: var(--spacing-md);
  animation: slideUp 1.2s ease-in-out;
}

/* Floating elements are now in the about section */
.floating-graphics {
  position: relative;
  height: 120px;
  width: 100%;
  margin: var(--spacing-md) 0;
  max-width: 300px;
  display: inline-block;
}

.floating-element {
  position: absolute;
  border-radius: var(--border-radius-md);
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  opacity: 0.8;
}

.dice {
  width: 50px;
  height: 50px;
  background-color: var(--color-primary);
  border-radius: var(--border-radius-md);
  left: 20%;
  top: 20%;
  animation-name: float;
  animation-delay: 0.2s;
  box-shadow: var(--shadow-md);
  position: relative;
}

.dice::before, .dice::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
}

.dice::before {
  top: 8px;
  left: 8px;
}

.dice::after {
  bottom: 8px;
  right: 8px;
}

.coin {
  width: 40px;
  height: 40px;
  background: linear-gradient(135deg, #FFD700, #FFA500);
  border-radius: 50%;
  right: 30%;
  top: 40%;
  animation-name: float2;
  animation-delay: 0.5s;
  box-shadow: var(--shadow-md);
}

.number {
  width: 35px;
  height: 35px;
  background-color: var(--color-secondary);
  color: white;
  border-radius: var(--border-radius-circle);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 700;
  left: 50%;
  top: 60%;
  animation-name: float3;
  animation-delay: 0.8s;
  box-shadow: var(--shadow-md);
}

@keyframes float {
  0%, 100% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-15px) rotate(10deg); }
}

@keyframes float2 {
  0%, 100% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(10px) rotate(-5deg); }
}

@keyframes float3 {
  0%, 100% { transform: translate(0, 0); }
  50% { transform: translate(-10px, -5px); }
}

/* Tools section */
.tools-section {
  padding: var(--spacing-lg) var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
}

.section-title {
  font-family: var(--heading-font-family);
  font-size: 2rem;
  text-align: center;
  margin-bottom: var(--spacing-xl);
  color: var(--color-text-primary);
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 25%;
  width: 50%;
  height: 3px;
  background: linear-gradient(to right, var(--color-primary), var(--color-secondary));
}

.tool-card-link {
  text-decoration: none;
  color: var(--color-text-primary);
  display: block;
  height: 100%;
}

.tool-card {
  height: 100%;
  border: none;
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.tool-card:hover {
  transform: translateY(-10px);
  box-shadow: var(--shadow-lg);
}

.tool-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, var(--color-primary), var(--color-secondary));
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
  border-radius: var(--border-radius-lg);
}

.tool-card:hover::before {
  opacity: 0.05;
}

.tool-icon-wrapper {
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.tool-icon {
  font-size: 2.5rem;
  transition: all 0.3s ease;
}

.tool-card:hover .tool-icon {
  transform: scale(1.1);
}

.tool-card .card-title {
  font-family: var(--heading-font-family);
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: var(--spacing-sm);
  color: var(--color-text-primary);
}

.tool-card .card-text {
  color: var(--color-text-secondary);
  font-size: 0.95rem;
}

/* About section */
.about-section {
  background-color: var(--color-light-gray);
  padding: var(--spacing-xl) var(--spacing-lg);
  border-radius: var(--border-radius-lg);
  margin: 0 var(--spacing-lg) var(--spacing-xl);
  text-align: center;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}

.about-section p {
  font-size: 1.1rem;
  line-height: 1.7;
}

/* Footer */
.home-footer {
  text-align: center;
  padding: var(--spacing-lg) 0;
  color: var(--color-text-secondary);
  border-top: 1px solid var(--color-border);
  margin-top: var(--spacing-xl);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .app-title {
    font-size: 2.2rem;
  }
  
  .app-subtitle {
    font-size: 1.1rem;
  }
  
  .section-title {
    font-size: 1.8rem;
  }
  
  .about-section p {
    font-size: 1rem;
  }
  
  .floating-graphics {
    height: 100px;
  }
  
  .dice {
    width: 40px;
    height: 40px;
  }
  
  .coin {
    width: 35px;
    height: 35px;
  }
  
  .number {
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .home-container {
    padding: var(--spacing-md) 0;
  }
  
  .hero-section, .tools-section, .about-section {
    padding: var(--spacing-md);
  }
  
  .tool-icon {
    font-size: 2rem;
  }
  
  .tool-card .card-title {
    font-size: 1.1rem;
  }
  
  .tool-card .card-text {
    font-size: 0.9rem;
  }
  
  .about-section {
    margin: 0 var(--spacing-md) var(--spacing-md);
  }
}