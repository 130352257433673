/* Global CSS styles based on our theme */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Playfair+Display:wght@400;500;600;700&display=swap');

:root {
  /* Colors from theme */
  --color-primary: #5E60CE;
  --color-secondary: #64DFDF;
  --color-tertiary: #80FFDB;
  --color-background: #FFFFFF;
  --color-card-background: #F8F9FA;
  --color-text-primary: #333333;
  --color-text-secondary: #6C757D;
  --color-success: #48BF84;
  --color-warning: #F9C74F;
  --color-error: #F25C54;
  --color-border: #E9ECEF;
  --color-light-gray: #F1F3F5;
  --color-dark-gray: #495057;
  
  /* Typography */
  --font-family: 'Poppins', 'Helvetica Neue', sans-serif;
  --heading-font-family: 'Playfair Display', serif;
  
  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-xxl: 3rem;
  
  /* Border Radius */
  --border-radius-sm: 0.25rem;
  --border-radius-md: 0.5rem;
  --border-radius-lg: 1rem;
  --border-radius-xl: 2rem;
}

/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  color: var(--color-text-primary);
  background-color: var(--color-background);
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font-family);
  font-weight: 600;
  margin-bottom: var(--spacing-md);
  color: var(--color-text-primary);
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  margin-bottom: var(--spacing-md);
  color: var(--color-text-secondary);
}

/* Custom button styles */
.btn-primary {
  background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
  border: none;
  color: white;
  padding: 0.6rem 1.2rem;
  border-radius: var(--border-radius-md);
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 4px 14px rgba(94, 96, 206, 0.25);
}

.btn-primary:hover, .btn-primary:focus {
  box-shadow: 0 6px 20px rgba(94, 96, 206, 0.35);
  transform: translateY(-2px);
  background: linear-gradient(45deg, #5152b8, #4fd0d0);
}

.btn-secondary {
  background: linear-gradient(45deg, var(--color-secondary), var(--color-tertiary));
  border: none;
  color: var(--color-text-primary);
  padding: 0.6rem 1.2rem;
  border-radius: var(--border-radius-md);
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 4px 14px rgba(100, 223, 223, 0.25);
}

.btn-secondary:hover, .btn-secondary:focus {
  box-shadow: 0 6px 20px rgba(100, 223, 223, 0.35);
  transform: translateY(-2px);
}

/* Card styles */
.card {
  background-color: var(--color-card-background);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  overflow: hidden;
  margin-bottom: var(--spacing-lg);
}

.card:hover {
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.card-header {
  background-color: var(--color-primary);
  color: white;
  padding: var(--spacing-md);
  font-family: var(--heading-font-family);
}

.card-body {
  padding: var(--spacing-lg);
}

/* Number ball styles */
.number-ball {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  margin: 5px;
  font-size: 18px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.number-ball:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.number-ball.primary {
  background-color: var(--color-primary);
  color: white;
}

.number-ball.secondary {
  background-color: var(--color-secondary);
  color: var(--color-text-primary);
}

.number-ball.tertiary {
  background-color: var(--color-tertiary);
  color: var(--color-text-primary);
}

/* Container styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-lg);
}

/* Section spacing */
.section {
  padding: var(--spacing-xxl) 0;
}

/* Add a beautiful gradient background utility */
.gradient-bg {
  background: linear-gradient(135deg, var(--color-primary), var(--color-secondary));
  color: white;
}

/* Animation classes */
.fade-in {
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.slide-up {
  animation: slideUp 0.5s ease-out;
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}
