.lottery-picker {
  padding: var(--spacing-lg) 0;
}

.lottery-card {
  border: none;
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  overflow: hidden;
  margin-bottom: var(--spacing-xl);
}

.lottery-card .card-header {
  background: linear-gradient(135deg, var(--color-primary), var(--color-secondary));
  border: none;
  padding: var(--spacing-md) var(--spacing-lg);
}

.lottery-title {
  font-family: var(--heading-font-family);
  color: white;
  margin: 0;
  font-size: 1.8rem;
}

.form-container {
  margin: var(--spacing-md) 0;
}

.form-input {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  padding: 0.6rem 1rem;
  width: 100%;
  transition: all 0.3s ease;
}

.form-input:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 0.2rem rgba(94, 96, 206, 0.25);
}

.generate-button {
  background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: var(--border-radius-md);
  font-weight: 500;
  box-shadow: var(--shadow-sm);
  transition: all 0.3s ease;
}

.generate-button:hover:not(:disabled) {
  box-shadow: var(--shadow-md);
  transform: translateY(-2px);
}

.generate-button:disabled {
  background: linear-gradient(45deg, #a5a6e0, #a7e7e7);
  opacity: 0.7;
}

.lottery-tickets {
  padding: var(--spacing-md) 0;
}

.loading-spinner {
  padding: var(--spacing-lg) 0;
}

.tickets-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tickets-container h5 {
  font-family: var(--heading-font-family);
  color: var(--color-text-primary);
  margin-bottom: var(--spacing-md);
}

.ticket-wrapper {
  background: white;
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-md);
  width: 100%;
  transition: all 0.3s ease;
  animation: fadeIn 0.6s ease-out;
}

.ticket-wrapper:hover {
  box-shadow: var(--shadow-md);
  transform: translateY(-3px);
}

.ticket-number {
  font-weight: 600;
  color: var(--color-primary);
  margin-bottom: var(--spacing-sm);
  font-size: 1rem;
  text-align: center;
}

.ticket {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) 0;
  overflow-x: auto;
  width: 100%;
}

.number-ball {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-weight: 600;
  color: white;
  margin: 3px;
  font-size: 0.9rem;
  box-shadow: var(--shadow-sm);
  animation: ballPop 0.5s ease-out;
  animation-fill-mode: both;
}

.number-ball.first-range {
  background: linear-gradient(135deg, var(--color-primary), var(--color-secondary-dark));
}

.number-ball.second-range {
  background: linear-gradient(135deg, #FF9800, #F57C00);
}

.ball-divider {
  display: inline-block;
  width: 2px;
  height: 36px;
  background-color: var(--color-light-gray);
  margin: 0 var(--spacing-sm);
}

.timestamp-container {
  margin-top: var(--spacing-md);
  padding: var(--spacing-md);
  background-color: var(--color-light-gray);
  border-radius: var(--border-radius-md);
  width: 100%;
  max-width: 350px;
}

.time-elapsed {
  margin-top: var(--spacing-xs);
  color: var(--color-text-secondary);
  font-size: 0.9rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ballPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .number-ball {
    width: 32px;
    height: 32px;
    font-size: 0.85rem;
    min-width: 32px;
  }
  
  .lottery-title {
    font-size: 1.6rem;
  }
  
  .ticket-wrapper {
    padding: var(--spacing-sm);
  }
  
  .ball-divider {
    height: 32px;
  }
}

@media (max-width: 576px) {
  .number-ball {
    width: 28px;
    height: 28px;
    min-width: 28px;
    font-size: 0.8rem;
    margin: 2px;
  }
  
  .lottery-title {
    font-size: 1.4rem;
  }
  
  .lottery-picker {
    padding: var(--spacing-md) var(--spacing-sm);
  }
  
  .ticket {
    gap: 2px;
  }
  
  .ball-divider {
    height: 28px;
    margin: 0 var(--spacing-xs);
  }
}
