/* Header styles using our theme variables */
.header-navbar {
  background: linear-gradient(135deg, var(--color-primary), var(--color-secondary));
  padding: 0.8rem 0;
  box-shadow: var(--shadow-md);
  position: sticky;
  top: 0;
  z-index: var(--z-index-sticky);
}

.brand {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  text-decoration: none;
}

.logo {
  height: 40px;
  margin-right: 12px;
  transition: transform 0.3s ease;
}

.brand:hover .logo {
  transform: scale(1.05);
}

.brand-name {
  font-family: var(--heading-font-family);
  font-size: 1.6rem;
  font-weight: 600;
  color: white;
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0.8));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-links-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.8rem;
}

.nav-link {
  color: rgba(255, 255, 255, 0.85) !important;
  font-weight: 500;
  padding: 0.5rem 1rem !important;
  border-radius: var(--border-radius-md);
  transition: all 0.2s ease;
  position: relative;
}

.nav-link:hover, .nav-link:focus {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: white;
  transition: width 0.2s ease;
}

.nav-link:hover::after {
  width: 70%;
}

.menu-toggle {
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.menu-toggle:hover {
  transform: scale(1.1);
}

/* Mobile menu styling */
.mobile-menu {
  width: 280px;
}

.mobile-menu .offcanvas-header {
  background: linear-gradient(135deg, var(--color-primary), var(--color-secondary));
  color: white;
}

.mobile-menu .offcanvas-title {
  font-family: var(--heading-font-family);
  font-weight: 600;
}

.mobile-nav-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.mobile-nav-link {
  padding: 0.8rem 1rem;
  color: var(--color-text-primary) !important;
  font-weight: 500;
  border-radius: var(--border-radius-md);
  transition: all 0.2s ease;
}

.mobile-nav-link:hover, .mobile-nav-link:focus {
  background-color: rgba(94, 96, 206, 0.1);
  color: var(--color-primary) !important;
}

@media (max-width: 992px) {
  .brand-name {
    font-size: 1.4rem;
  }
  
  .logo {
    height: 35px;
  }
}
