.teams-page {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: var(--body-font-family);
  color: var(--color-text-primary);
  background-color: var(--color-background);
}

.reshuffle-button {
  margin-bottom: 20px;
  align-self: flex-end;
  background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
  border: none;
  color: white;
  font-weight: 500;
  box-shadow: var(--shadow-sm);
  transition: all 0.3s ease;
}

.reshuffle-button:hover {
  box-shadow: var(--shadow-md);
  transform: translateY(-2px);
}

.share-button, .select-jack-button {
  margin-bottom: 20px;
  align-self: flex-end;
  margin-left: 10px;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease;
}

.share-button:hover, .select-jack-button:hover {
  background-color: var(--color-primary-light);
  transform: translateY(-2px);
  box-shadow: var(--shadow-sm);
}

.teams-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.team-column {
  width: 48%;
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-md);
  background: white;
  transition: all 0.3s ease;
}

.team-column:hover {
  box-shadow: var(--shadow-lg);
}

.team-column.team-1 {
  border-top: 5px solid var(--color-primary);
}

.team-column.team-2 {
  border-top: 5px solid var(--color-secondary);
}

.team-name-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to right, rgba(94, 96, 206, 0.1), rgba(100, 223, 223, 0.1));
  padding: 8px 0;
}

.team-name-input {
  width: 90%;
  padding: 8px 12px;
  border: none;
  font-weight: bold;
  text-align: left;
  background-color: transparent;
  font-family: var(--heading-font-family);
  font-size: 1.2rem;
  color: var(--color-text-primary);
}

.sort-icon {
  cursor: pointer;
  margin-right: 8px;
  color: var(--color-primary);
  transition: transform 0.2s ease;
}

.sort-icon:hover {
  transform: scale(1.2);
}

.player-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.player-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;
  border-bottom: 1px solid var(--color-border);
  background-color: white;
  transition: background-color 0.2s ease;
  align-items: center;
}

.player-item:hover {
  background-color: var(--color-light-gray);
}

.player-name {
  flex-grow: 1;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.player-actions {
  display: flex;
  gap: 8px;
  margin-left: 10px;
}

.action-icon {
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.2s ease;
}

.action-icon:hover {
  opacity: 1;
  transform: scale(1.2);
}

.player-options-icon {
  cursor: pointer;
  color: var(--color-text-secondary);
}

.unassigned-players {
  margin-top: 20px;
  padding: 15px;
  background: white;
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm);
}

.unassigned-players h2 {
  margin-bottom: 16px;
  font-size: 18px;
  font-family: var(--heading-font-family);
  color: var(--color-text-primary);
  position: relative;
  padding-bottom: 8px;
}

.unassigned-players h2:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
  border-radius: 3px;
}

.unassigned-player-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.unassigned-player-item {
  padding: 8px 12px;
  background-color: var(--color-light-gray);
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: all 0.2s ease;
}

.unassigned-player-item:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-sm);
  background-color: rgba(94, 96, 206, 0.1);
}

.add-player {
  display: flex;
  margin-bottom: 20px;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.add-player .ant-input {
  border-color: var(--color-border);
  border-radius: var(--border-radius-sm);
}

.add-player .ant-input:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px rgba(94, 96, 206, 0.2);
}

.add-player .ant-btn {
  background: var(--color-primary);
  border-color: var(--color-primary);
  color: white;
}

.game-settings {
  margin-top: 20px;
  padding: 15px;
  border-radius: var(--border-radius-md);
  background: white;
  box-shadow: var(--shadow-sm);
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.game-settings-display {
  background: white;
  border-radius: var(--border-radius-md);
  padding: 15px;
  box-shadow: var(--shadow-sm);
}

.setting-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.setting-label {
  font-weight: 600;
  color: var(--color-primary);
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.setting-item label {
  font-weight: 500;
  color: var(--color-text-primary);
}

.player-rating {
  background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
  color: white;
  font-weight: bold;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

/* Share Teams Modal Styling */
.ant-modal-content {
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-lg);
}

.ant-modal-header {
  background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
  border-bottom: none;
  padding: 16px 24px;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-modal-title {
  color: white;
  font-family: var(--heading-font-family);
  font-size: 1.5rem;
  text-align: center;
}

.ant-modal-close {
  color: white;
}

.ant-modal-body {
  padding: 24px;
  background-color: #f8f9fa;
}

.shared-view {
  font-family: var(--body-font-family);
}

.shared-view h2 {
  font-family: var(--heading-font-family);
  color: var(--color-text-primary);
  border-bottom: 2px solid var(--color-primary);
  padding-bottom: 8px;
  margin-bottom: 16px;
  position: relative;
}

.shared-view h2:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: var(--color-secondary);
}

.shared-view .teams-container {
  background: white;
  border-radius: var(--border-radius-md);
  padding: 15px;
  margin-bottom: 24px;
  box-shadow: var(--shadow-sm);
}

.shared-view .team-column {
  box-shadow: none;
  background: linear-gradient(to bottom, rgba(94, 96, 206, 0.05), rgba(100, 223, 223, 0.05));
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
}

.shared-view h3 {
  background: linear-gradient(to right, var(--color-primary), var(--color-secondary));
  color: white;
  padding: 10px 15px;
  margin: 0;
  font-family: var(--heading-font-family);
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
}

.shared-view .player-item {
  padding: 10px 15px;
  background: transparent;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.shared-view .player-item:last-child {
  border-bottom: none;
}

.shared-view .player-name {
  font-weight: 500;
  color: var(--color-text-primary);
}

.shared-view p {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.shared-view p:before {
  content: "•";
  color: var(--color-primary);
  font-weight: bold;
}

@media (max-width: 768px) {
  .teams-container {
    flex-direction: column;
  }
  
  .team-column {
    width: 100%;
  }
  
  .shared-view .teams-container {
    flex-direction: column;
  }
  
  .shared-view .team-column {
    width: 100%;
    margin-bottom: 15px;
  }
  
  .game-settings {
    flex-direction: column;
    align-items: flex-start;
  }
}
