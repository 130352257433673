.player-tile {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 8px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(25% - 8px);
  max-width: 150px;
  height: 60px;
  box-sizing: border-box;
  touch-action: manipulation;
}

.player-tile:hover, .player-tile:active {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.player-tile.selected {
  background-color: #e6f7ff;
  border-color: #1890ff;
}

.player-name {
  font-size: 0.9rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.player-icons {
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  align-items: center;
}

.icon {
  width: 16px;
  height: 16px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 2px;
  border-radius: 50%;
  transition: background-color 0.2s ease;
}

.icon-container:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.icon-container:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.icon-no-bowling {
  color: #a30505;
}

/* Add a subtle animation for toggling */
@keyframes iconPulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.icon-container:active .icon {
  animation: iconPulse 0.3s ease;
}

@media (max-width: 768px) {
  .player-tile {
    width: calc(33.33% - 8px);
  }
}

@media (max-width: 480px) {
  .player-tile {
    width: calc(50% - 8px);
  }
}